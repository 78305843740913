export const dateFormat = (time) => {
  const date = new Date(time)
  const year = date.getFullYear()
  /* 在日期格式中，月份是从0开始的，因此要加0
     * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
     * */
  const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  // 拼接
  return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
}

// timestamp to x year x month x day
export const dayFormat = (time) => {
  const date = new Date(time)
  const year = date.getFullYear()
  const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  return year + '-' + month + '-' + day
}

export const monthFormat = (time) => {
  const date = new Date(time)
  const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  // 拼接
  return month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
}

export const dayHourMinuteToSeconds = (day, hour, minute) => {
  return day * 24 * 60 * 60 + hour * 60 * 60 + minute * 60
}

// timestamp to x day x hour x minute x second
export const secondsToStr = (time) => {
  const days = parseInt(time / (60 * 60 * 24))
  const hours = parseInt((time % (60 * 60 * 24)) / (60 * 60))
  const minutes = parseInt((time % (60 * 60)) / (60)
  )
  const seconds = parseInt((time % (60)))

  let timeText = ''

  if (days > 0) {
    timeText += days + '天'
  }
  if (hours > 0) {
    timeText += hours + '時'
  }
  if (minutes > 0) {
    timeText += minutes + '分'
  }
  if (seconds > 0 || time === '') {
    timeText += seconds + '秒'
  }

  return timeText
  // return days + ' 天 ' + hours + ' 小时 ' + minutes + ' 分 ' + seconds + ' 秒'
}

// timestamp to x day x hour x minute
export const minuteToStr = (time) => {
  const days = parseInt(time / (60 * 60 * 24))
  const hours = parseInt((time % (60 * 60 * 24)) / (60 * 60))
  const minutes = parseInt((time % (60 * 60)) / (60))
  return days + ' 天 ' + hours + ' 小时 ' + minutes + ' 分 '
}

// timestamp to hour x minute x second
export const timeStamp = (time) => {
  const date = new Date(time)
  const dateTimeString = `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`
  return dateTimeString
}

export const secondsToDay = (time) => {
  const days = parseInt(time / (60 * 60 * 24))
  return days
}

export const secondsToHour = (time) => {
  const hours = parseInt((time % (60 * 60 * 24)) / (60 * 60))
  return hours
}

export const secondsToMinute = (time) => {
  const minutes = parseInt((time % (60 * 60)) / (60))
  return minutes
}
